/* Font Faces */

@font-face {
    font-family: Pizzicato;
    src:  url('../fonts/pizzicato.ttf')  format('truetype');
    font-weight:900;
}

/*
* Coming Soon Page CSS
*/

.home-bg-image {
    background: rgba(0, 0, 0, 0) url("../images/tornado.jpg") no-repeat scroll center center / cover;
    
}

.logo {
    font-size: 80px;
    font-family: Pizzicato;
    font-weight: bold;
}